<template>
  <div class="declaration wrapper">
    <a-spin :spinning="loading">
      <div class="">
        <a-empty
          v-if="declaration.length == 0"
          description="暂无信息"
        ></a-empty>
        <div v-else class="flex flex-between declaration-box-main">
          <template v-for="(item, index) in declaration" :key="index">
            <div
              class="declaration-item flex flex-between"
              @click="goToDeclarationDetail(item)"
            >
              <div>
                <div class="declaration-title">
                  {{ item.declaration_title }}
                </div>
                <div class="flex">
                  <div class="declaration-park" v-if="item.park_name">
                    {{ item.park_name }}
                  </div>
                  <div class="declaration-time">
                    {{ item.declaration_time }}
                  </div>
                </div>
              </div>
              <right-outlined />
            </div>
          </template>
          <div
            class="declaration-item flex flex-between"
            style="border-bottom: none"
          >
            <div class="mybtn" @click="gomy">
              <div>
                <div>申报</div>
                <div>记录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getDeclarationList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserData } from "@/utils/storeData.js";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "declaration",
  components: {
    RightOutlined,
  },
  setup() {
    const pageData = reactive({
      declaration: [],
      userData: computed(() => getUserData()),
      page: 1,
      limit: 16,
      total: 0,
      loading: false,
    });
    const getDeclarationListFun = () => {
      pageData.loading = true;
      getDeclarationList({
        page: pageData.page,
        limit: pageData.limit,
        park_id: pageData.userData?.user_park_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.declaration = res.data.declaration;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goToDeclarationDetail = (_item) => {
      url.navigateTo("/declaration/detail", {
        t: encode(`${_item.declaration_id}`),
      });
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => pageData.page,
        () => pageData.limit,
      ],
      (val) => {
        getDeclarationListFun();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const gomy = () => {
      url.navigateTo("/declaration/recordlist");
    };
    return {
      ...toRefs(pageData),
      goToDeclarationDetail,
      onShowSizeChange,gomy
    };
  },
});
</script>
<style lang="less" scoped>
.mybtn {
  width: 80px;
  height: 80px;
  background-color: #1276cb;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.declaration {
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .declaration-box-main {
    flex-wrap: wrap;
    .declaration-item {
      width: 100%;
      height: 110px;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;
      .declaration-title {
        color: #212531;
        font-size: 18px;
        margin-bottom: 10px;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .declaration-park {
        min-width: 80px;
        height: 22px;
        line-height: 22px;
        border-radius: 11px;
        border: solid 1px #1276cb;
        color: #1276cb;
        font-size: 14px;
        text-align: center;
        margin-right: 20px;
      }
      .declaration-time {
        color: #212531;
        opacity: 0.7;
        font-size: 16px;
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
